<template>
  <div id="VsBox">
    <!-- <leftFloat :tabList="tabList" @currentChange="currentChange" /> -->
    <div class="VsBox-box">
      <!-- <UserHeader>对战详情</UserHeader> -->
      <div class="header-box">
        <div class="back-box1" @click="$router.go(-1)"></div>
        <div>对战详情</div>
      </div>
      <div class="Navigation2">
        <div class="Navigation-FE">
          <div class="box-list">
            <div class="box-item" v-for="(item, index) in gameDetail.game_arena_box" :key="index" :class="{ active: isInProgress && index == ChangCiNum - 1 }" @click="OpenXZshow(item)">
              <div class="pic">
                <img class="img1" :src="item.box.intact_cover" alt="" width="60%" />
                <img class="img2" :src="item.box.weapon_cover" alt="" width="60%" />
              </div>
              <div class="name">{{ item.box.name }}</div>
              <!-- <div class="price"> <img style="width: 0.2rem;margin-right: 0.05rem;"
                  src="@/assets/images/about/user/huobi.png" alt="">{{ item.box.game_bean }}</div> -->
              <div class="price">
                <Cprice :size="0.1" :price="item.box.game_bean" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-info">
        <div class="p">
          <div style="width:0.28rem;white-space:nowrap;">总计：</div>
          <Cprice :price="gameDetail.total_bean" :size="0.12" />
        </div>
        <div class="round">
          {{
              ChangCiNum > gameDetail.box_num ? gameDetail.box_num : ChangCiNum
            }}
          / {{ gameDetail.box_num }}
        </div>
        <div>
        </div>
      </div>
      <div v-if="ItemArr" class="VS-core">
        <div v-if="gameDetail.id" class="room-code">
          <div class="audio-box">
            <div v-if="!isInProgress" class="audio-img" @click="mp3PlayOnClick">
              <img style="width: 0.2rem" :src="
                  soundStatus
                    ? require('@/assets/images/about/user/audioopen-new.png')
                    : require('@/assets/images/about/user/audioclose-new.png')
                " alt="" />
            </div>
          </div>
          <div style="font-size:0.1rem">房间号 {{ gameDetail.id }}</div>
        </div>
        <div class="all-room-wrap">
          <div class="all-room" :style="`width:${100 / ItemArr.length}%`" v-for="(item, index) in ItemArr" :key="index">
            <OpenCase v-if="showOpenCase" class="room-item" @addChangCiNum="addChangCiNum" :isInProgress="isInProgress" :gameDetail="gameDetail" :playerItem="item" :ChangCiNum="ChangCiNum" :isEnd="isEnd" :key="ArrIndex * item.id" />
          </div>
        </div>
      </div>
      <OverlayBoxList @close="XZshow = false" :XZshow="XZshow" :boxList="Treasure_box_prize" />

    </div>
    <van-overlay :lock-scroll="false" @mousewheel.prevent.native="() => {}" :show="overlayShow">
      <div class="popup-wrap" :class="{ 'popup-wrap-fail': isWinner !== true }" @click.stop>
        <img src="@/assets/images/battle/battleRoom/close-new.png" alt="" class="close point" @click="overlayShow = false" />
        <img src="@/assets/images/battle/battleRoom/win-bg-new.png" alt="" class="win-bg" />
        <div class="list-box">
          <Goods class="batter-goods" v-if="isWinner" :showPrice="true" :DetailList="winList"></Goods>
          <Goods class="batter-goods" v-else :showPrice="true" :DetailList="finalFailList"></Goods>
        </div>
        <div class="btn-box">
          <div class="btn" @click="overlayShow = false">
            放入背包
          </div>
        </div>

        <!-- <div v-if="isWinner" class="top-title">
          <img src="@/assets/images/battle/battleRoom/win-popup-text.png" alt="" class="title1" />
          <img src="@/assets/images/battle/battleRoom/btn-popup-text2.png" alt="" class="title2" />
        </div> -->
        <!-- <div v-else class="top-title">
          <img src="@/assets/images/battle/battleRoom/win-popup-text1.png" alt="" class="title1" />
          <img src="@/assets/images/battle/battleRoom/btn-popup-text3.png" alt="" class="title2" />
        </div> -->
        <!-- <div @mousewheel.stop="" class="popup-show-list">
          <Goods class="batter-goods" v-if="isWinner" :showPrice="true" :DetailList="winList"></Goods>
          <Goods class="batter-goods" v-else :showPrice="true" :DetailList="finalFailList"></Goods>
        </div> -->
        <!-- <div class="popup-bottom">
          <div @click="overlayShow = false" class="popup-btn p-btn1 point">
            取消
          </div>
          <div @click="overlayShow = false" class="popup-btn p-btn2 point">
            确认
          </div>
        </div> -->
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { Arena_Detail } from "@/network/api.js";
import OpenCase from "./components/OpenCase.vue";
import Goods from "@/components/PubOpen/Goods.vue";
import leftFloat from "@/components/left-float/index.vue";
import { tabList } from "./index.js";
import UserHeader from "@/components/common/UserHeader.vue";
import OverlayBoxList from "./components/OverlayBoxList.vue";
export default {
  name: "VsBox",
  data() {
    return {
      takeStatus: false,
      XZshow: false,
      // itemInfo: {}, //详情数据
      Treasure_box_prize: [], //宝箱奖品数据
      TreasureName: "",
      ItemArr: [],
      ArrIndex: [1, 2, 3, 4, 5, 6],
      ChangCiNum: 1, //场次

      RouterId: 0,
      JieShuData: {},

      JieShu: "",

      ShowLine: false, //对战时候中间的那根黄线
      gameDetail: {}, //对战详情
      overlayShow: false, //胜利失败弹窗
      finalFailList: [
        {
          id: 9999,
          level: 4,
          cover: require("@/assets/images/PublicImg/LL.png"),
          name: "安慰奖励",
          bean: "0.01",
          skins: {
            // cover: require("@/assets/images/PublicImg/LL.png"),
            // name: "安慰奖励",
            // bean: "0.01",
          },
        },
      ],
      finalWinList: [
        {
          id: 9998,
          level: 4,
          cover: require("@/assets/images/PublicImg/LL.png"),
          name: "胜利奖励",
          bean: "0.01",
          noneBean: true,
          skins: {
            // cover: require("@/assets/images/PublicImg/LL.png"),
            // name: "胜利奖励",
            // bean: "0.01",
          },
        },
      ],
      isEnd: false,
      isAllEnd: false,
      isInProgress: false,
      Timer: undefined,
      Timer2: undefined,
      showOpenCase: true,
      isAddChangCiNum: true,
      tabList,
      RouterCode: "",
      mp3: new Audio(require("@/assets/audios/openb.mp3")), //开箱过程声音
      boom: new Audio(require("@/assets/audios/boom.mp3")), //开箱结束声音
      audioplay: false, //声音播放
      startChouJiangStatus: false
    };
  },
  beforeDestroy() {
    this.mp3 && this.mp3.pause();
    this.mp3End && this.mp3End.pause();
    clearTimeout(this.Timer);
  },
  //activated
  created() {
    this.ChangeAudioStatus(true);
    this.RouterId = this.$route.query.id;
    this.RouterCode = this.$route.query.Code;
    if (this.RouterCode == 2) {
      this.GetArena_Detail().then((res) => {
        this.ChangCiNum = this.gameDetail?.game_arena_box?.length + 1;
        if (
          this.gameDetail.user_num === this.gameDetail.game_arena_player.length
        ) {
          console.log(33322);
          this.startChouJiang();
        }
      });
    } else {
      this.GetArena_Detail().then((res) => {
        if (
          this.gameDetail.user_num === this.gameDetail.game_arena_player.length
        ) {
          console.log(33333);
          this.startChouJiang();
        }
      });
    }
  },

  filters: {
    ImgRoute(str) {
      return localStorage.getItem("static_file_url") + str;
    },
  },
  methods: {
    ...mapMutations(["ChangeAudioStatus"]),
    mp3PlayOnClick() {
      if (this.soundStatus) {
        this.ChangeAudioStatus(false);
      } else {
        this.ChangeAudioStatus(true);
      }
    },
    currentChange(i) {
      this.$router.push({
        path: "/Battle",
        query: {
          activeTab: i,
        },
      });
    },
    addChangCiNum() {
      if (
        this.gameDetail.user_num === this.gameDetail.game_arena_player.length
      ) {
        if (
          this.isAddChangCiNum &&
          this.ChangCiNum <= this.gameDetail.game_arena_box?.length
        ) {
          this.isAddChangCiNum = false;
          this.ChangCiNum = this.ChangCiNum + 1;
          setTimeout(() => {
            this.isAddChangCiNum = true;
          }, 500);
          console.log(33344);
          this.startChouJiang();
        }
      }
      let htmlFontSize = getComputedStyle(window.document.documentElement)[
        "font-size"
      ].replace("px", "");
      const dom = document.querySelector(".box-list");
      const singleWidth = 0.89 * htmlFontSize;
      if (dom) {
        dom.scrollLeft = singleWidth * this.ChangCiNum - singleWidth;
      }
    },
    //开始抽奖
    startChouJiang() {
      if (this.startChouJiangStatus) {
        return false;
      }
      setTimeout(() => {
        this.startChouJiangStatus = false;
      }, 2000);
      this.startChouJiangStatus = true;
      console.log('开始抽奖1388');
      this.isInProgress = true;
      if (this.ChangCiNum > this.gameDetail.game_arena_box?.length) {
        this.isInProgress = false;
        if (this.RouterCode != 2) {
          if (!this.isAllEnd) {
            const userList = this.gameDetail.game_arena_player.map((item) => {
              return item?.user?.id;
            });
            if (this.isWinner) {
              this.overlayShow = true;
            }
          }
          this.isAllEnd = true;
        }
        // clearTimeout(this.Timer);
      } else {
        if (this.Timer2) {
          clearTimeout(this.Timer2);
        }
        this.Timer2 = setTimeout(() => {
          if (this.soundStatus) {
            this.mp3 && this.mp3.pause();
            this.mp3 = null;
            this.mp3 = new Audio(require("@/assets/audios/openb.mp3"));
            this.mp3.play();
          }
        }, 0);
        this.Timer = setTimeout(
          () => {
            if (this.ChangCiNum > this.gameDetail.game_arena_box?.length) {
              this.isInProgress = false;
              if (this.RouterCode != 2) {
                if (!this.isAllEnd) {
                  const userList = this.gameDetail.game_arena_player.map(
                    (item) => {
                      return item?.user?.id;
                    }
                  );
                  if (!userList.includes(this.user.id)) {
                    this.overlayShow = true;
                  }
                }
                this.isAllEnd = true;
              }
              // clearInterval(this.Timer);
            }
            if (this.soundStatus) {
              this.mp3End && this.mp3End.play();
            }
          },
          this.RouterCode == 2 ? 0 : 6500
        );
      }
    },
    //获取对战详情
    async GetArena_Detail() {
      const res = await Arena_Detail(this.$route.query.id);
      this.ShowLine = true;
      this.gameDetail = res.data.data;
      let Arr = new Array(res.data.data.user_num).fill(undefined);
      for (let k = 0; k < res.data.data.game_arena_player.length; k++) {
        Arr[Number(res.data.data.game_arena_player[k].seat)] =
          res.data.data.game_arena_player[k];
      }
      this.ItemArr = Arr;
      this.ItemArr = this.ItemArr.map((item, index) => {
        let winStatus = 1;
        let allList = [];
        let selfList = item?.game_award;
        let endMoney = 0;
        if (this.gameDetail.win_user_id?.length === this.ItemArr.length) {
          winStatus = 2;
          allList = selfList;
          item.game_award.forEach((vItem) => {
            endMoney += vItem.skins.bean * 1;
          });
        } else if (this.gameDetail.win_user_id?.includes(item.user_id)) {
          winStatus = 3;
          if (this.gameDetail.win_user_id.length > 1) {
            let winMoney = 0;
            this.ItemArr.forEach((vItem) => {
              if (!this.gameDetail.win_user_id?.includes(vItem.user_id)) {
                vItem.game_award.forEach((vvItem) => {
                  winMoney += vvItem.skins.bean * 1;
                });
              }
              vItem.game_award.forEach((vvItem) => {
                endMoney += vvItem.skins.bean * 1;
              });
            });
            this.finalWinList[0].bean = winMoney / 2;
            allList = [...selfList, ...this.finalWinList];
          } else {
            this.ItemArr.forEach((vItem) => {
              if (!this.gameDetail.win_user_id?.includes(vItem.user_id)) {
                allList = [...allList, ...vItem.game_award];
              }
              vItem.game_award.forEach((vvItem) => {
                endMoney += vvItem.skins.bean * 1;
              });
            });
            allList = [...selfList, ...allList];
          }
          endMoney = endMoney / this.gameDetail.win_user_id.length;
          if (this.gameDetail.win_user_id.length > 1) {
            allList = allList.map((item) => {
              if (!item.noneBean) {
                return {
                  ...item,
                  bean: endMoney,
                };
              } else {
                return {
                  ...item
                };
              }
            });
          }
        } else {
          endMoney = 0.01;
          winStatus = 1;
          allList = this.finalFailList;
        }
        return {
          ...item,
          allList,
          selfList,
          index,
          winStatus, // 1失败，2平局 3 胜利
          endMoney,
        };
      });
      if (
        this.gameDetail.user_num === this.gameDetail.game_arena_player.length
      ) {
        this.isEnd = true;
      }
      console.log('获取了详情');
      // this.ChangCiNum = 0
      // setTimeout(() => {
      //   this.ChangCiNum = 1
      // }, 0);
      //status状态 0:等待中  1:已开始  2:已结束
    },

    // getChild(val) {
    //   if (val.id == this.$route.query.id) {
    //     if (val.Draw_times <= this.gameDetail?.box_num) {
    //       this.ChangCiNum = val.Draw_times;
    //       let htmlFontSize = getComputedStyle(window.document.documentElement)['font-size'].replace('px', '');
    //       const dom = document.querySelector('.box-list');
    //       const singleWidth = 2.95 * htmlFontSize
    //       if (dom) {
    //         dom.scrollLeft = singleWidth * this.ChangCiNum - singleWidth
    //       }
    //     }
    //     // this.GetMusic();
    //   }
    // },

    //音频播放
    // GetMusic() {
    //   let music = document.getElementById("music");
    //   if (music) {
    //     music.muted = false;
    //   }
    // },

    //结束_接收
    // childByValue(val) {
    //   this.JieShu = val;
    // },

    //对战开始
    // StartGroup(data) {
    //   this.GetVsKaiShi(data);
    //   if (data.game_arena_id == this.gameDetail.id) {
    //     this.gameDetail.status = data.status;
    //   }
    // },

    //打开箱子弹框
    OpenXZshow(item) {
      this.XZshow = true;
      this.TreasureName = item.box.name;
      this.Treasure_box_prize = item.box.contains;
      // this.game_odds_list = item.game_odds_list;
    },

    //关闭箱子弹框
    // CloseXZshow() {
    //   this.XZshow = false;
    // },

    //刷新
    Refresh() {
      this.showOpenCase = false;
      this.GetArena_Detail();
      this.ArrIndex = this.ArrIndex.map((item) => {
        return item + 1;
      });
      setTimeout(() => {
        this.showOpenCase = true;
      }, 100);
    },

    ...mapActions(["GetVsKaiShi", "GetVsJieShu"]),
  },
  // mounted() {
  //   setTimeout(() => {
  //   }, 2000);
  // },
  computed: {
    ...mapState([
      "user",
      "AddInformation",
      "roundNum",
      "KaiShiVsData",
      "JieShuVsData",
      "mp3Start",
      "mp3End",
      "soundStatus",
    ]),
    // 是否是赢家，展示赢家弹窗
    isWinner() {
      if (this?.gameDetail?.win_user_id?.includes(this.user?.id)) {
        return true;
      }
      return false;
    },
    //  是否是平局
    isPing() {
      let flag = false;
      if (
        this.gameDetail.win_user_id?.length &&
        this.gameDetail.win_user_id?.length ===
        this.gameDetail?.game_arena_player?.length
      ) {
        flag = true;
      }
      return flag;
    },
    //   winList() {
    //     let arr = [];
    //     if (this.gameDetail?.game_arena_player?.length) {
    //       for (let a = 0; a < this.gameDetail.game_arena_player.length; a++) {
    //         arr = arr.concat(this.gameDetail.game_arena_player[a].game_award);
    //       }
    //     }
    //     if (this.isPing) {
    //       arr = this.gameDetail.game_arena_player[0].game_award;
    //     }
    //     arr = arr.map((item) => {
    //       return {
    //         ...item,
    //         ...item.skins,
    //       };
    //     });
    //     return arr;
    //   },
    // },
    winList() {
      let arr = [];
      arr = this.ItemArr.filter(item => {
        return item.user_id === this.user?.id;
      })[0]?.allList;
      // if (this.gameDetail?.game_arena_player?.length) {
      //   for (let a = 0; a < this.gameDetail.game_arena_player.length; a++) {
      //     arr = arr.concat(this.gameDetail.game_arena_player[a].game_award);
      //   }
      // }
      // if (this.isPing) {
      //   arr = this.gameDetail.game_arena_player[0].game_award;
      // }
      arr = arr?.map((item) => {
        return {
          ...item,
          ...item.skins,
        };
      });
      console.log(12345, arr);
      return arr;
    },
  },
  watch: {
    // isEnd() {
    //   if (this.isEnd) {
    //     setTimeout(() => {
    //       this.ChangCiNum = 1
    //     }, 1000);
    //   }
    // },
    // RouterId: {
    //   handler() {
    //     // this.ChangCiNum = 1;
    //     // this.overlayShow = false
    //     this.GetArena_Detail();
    //   },
    //   immediate: true,
    //   deep: true
    // },

    // JieShu(val) {
    //   if (val == "结束") {
    //     this.$route.query.Code = 2;
    //     // this.GetArena_Detail();
    //     this.overlayShow = true
    //   }
    // },

    AddInformation(val) {
      console.log("AddInformation加入变化", val);
      if (val.game_arena_id == this.$route.query.id) {
        this.ItemArr[Number(val.seat)] = val;
        this.Refresh();
      }
    },
    KaiShiVsData(val) {
      console.log("AddStartGroup对战开始变化", val);
      // if (val.id == this.$route.query.id) {
      // this.item.status = val.status
      // this.item.status_alias = val.status_alias
      // }
    },
    JieShuVsData(val) {
      console.log("JieShuVsData结束变化", val);
      if (val.id == this.$route.query.id) {
        // this.ShowLine = true;
        // this.gameDetail = val;
        // this.gameDetail.status = val.status;
        this.Refresh();
        // this.ChangCiNum = 3
        console.log(33311);
        this.startChouJiang();
      }
    },
  },

  components: {
    OpenCase,
    Goods,
    leftFloat,
    UserHeader,
    OverlayBoxList,
  },
};
</script>

<style lang="scss">
.popup-wrap {
  width: 3.35rem;
  min-height: 1.78rem;
  background: rgba(8, 8, 15, 0.8);
  border: 0.02rem solid #434375;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -56%);
  padding: 0.12rem;
  box-sizing: border-box;
  // padding-top: 1.6rem;
  .list-box {
    .Goods {
      .Goods_box {
        .Goods_list {
          max-height: 1.8rem;
          overflow-y: scroll;
          .Goods_item {
            width: 0.7rem;
            height: 0.99rem;
            margin: 0 0.033rem;
            margin-bottom: 0.05rem;
            // padding: 0.08rem 0.05rem 0.05rem;
          }
        }
      }
    }
  }
  .close {
    position: absolute;
    top: -0.22rem;
    right: 0rem;
    width: 0.12rem;
    height: 0.12rem;
  }
  .win-bg {
    position: absolute;
    top: -0.82rem;
    left: 0.12rem;
    width: 3.11rem;
    height: 0.94rem;
  }
  .list-box {
    width: 100%;
    min-height: 1.2rem;
    background: url(../../assets/images/battle/battleRoom/popup-bg2.png)
      no-repeat;
    background-size: 100% 100%;
    margin-bottom: 0.09rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      width: 0.9rem;
      height: 0.26rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url(../../assets/images/battle/battleRoom/popup-bg3.png)
        no-repeat;
      background-size: 100% 100%;
      font-size: 0.14rem;
    }
  }

  .top-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;

    .title1 {
      width: 0.42rem;
      height: 0.19rem;
    }

    .title2 {
      width: 1.04rem;
      height: 0.28rem;
    }
  }

  .popup-bottom {
    width: 100%;
    height: 0.45rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    bottom: 0;

    .popup-btn {
      width: 0.72rem;
      height: 0.24rem;
      text-align: center;
      line-height: 0.22rem;
      font-size: 0.12rem;
      color: #fff;
      // font-size: 0.3rem;
      font-weight: 400;
      background: url(../../assets/images/battle/battleRoom/btn-blue.png)
        no-repeat;
      background-size: 100% 100%;
    }

    .p-btn2 {
      background: url(../../assets/images/battle/battleRoom/btn-red.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }
}

.popup-wrap-fail {
  background: url(../../assets/images/battle/battleRoom/popup-bg1.png) no-repeat;
  background-size: 100% 100%;
}

.VsBox-box {
  width: 100%;
  // padding-top: 0.4rem;
  padding: 0.16rem 0.18rem;
  .header-box {
    width: 100%;
    // height: 0.4rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 0.16rem;
    margin-bottom: 0.12rem;
    position: relative;
    .back-box1 {
      position: absolute;
      width: 0.25rem;
      height: 0.14rem;
      left: 0rem;
      top: 0rem;
      z-index: 3;
      background: url(../../assets/images/new/back-icon.png) no-repeat center;
      background-size: 100% 100%;
    }
  }
  .sideBar {
    width: 2.26rem;
    // height: 9.19rem;
    background: url(../../assets/images/battle/battleRoom/sidebar-bg.png)
      no-repeat;
    background-size: 100% 100%;
    position: fixed;
    right: 0;
    top: 4rem;
    z-index: 1001;
    // overflow: hidden;

    .side-wrap {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .side-close {
      position: absolute;
      top: 4rem;
      left: 0.05rem;
      width: 0.16rem;
      height: 0.49rem;
      cursor: pointer;
    }

    .sideBar-top {
      box-sizing: border-box;
      padding: 0.4rem 0.2rem 0 0.5rem;
      text-align: center;

      .level-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .p-level {
          width: 50%;
          // font-size: 0.16rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ff9900;

          img {
            width: 0.24rem;
            height: 0.22rem;
          }
        }

        .p-level1 {
          color: #ff9900;
        }

        .p-level2 {
          color: #cc0000;
        }

        .p-level3 {
          color: #660099;
        }

        .p-level4 {
          color: #003399;
        }
      }
    }

    .sidebar-list {
      margin-top: 0.2rem;
      margin-left: 0.31rem;
      position: relative;

      .side-item {
        width: 1.93rem;
        height: 0.74rem;
        background: url(../../assets/images/battle/battleRoom/side-box-bg.png)
          no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 0.08rem 0 0.04rem 0.14rem;

        .img-box {
          width: 0.45rem;
          height: 0.49rem;
          background: url(../../assets/images/battle/battleRoom/sid-goog-bg.png)
            no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 0.06rem;
        }

        .text-title,
        .text2 {
          // font-size: 0.14rem;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #ffffff;
        }

        .price {
          // font-size: 0.14rem;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #ff9900;
        }
      }
    }
  }

  .Navigation2 {
    position: relative;
    // margin-top: 0.4rem;
    width: 100%;
    height: 0.88rem;
    // background: url(../../assets/images/battle/battleRoom/top-bg.png) no-repeat;
    // background-size: 100% 100%;
    background: url(../../assets/images/battle/title-bg3-new.png) no-repeat;
    background-size: 100% 100%;
    // background: red;
    // box-sizing: 0 24px 31px rgba(15, 16, 31, 0.71) !important;
    display: flex;
    justify-content: space-between;
    font-size: 0.12rem;
    padding: 0rem 0.04rem 0.06rem 0.04rem;
    margin-bottom: 0.1rem;

    .Navigation-FE {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      padding: 0 0.1rem;

      .round {
        width: 1.6rem;
        height: 100%;
        text-align: center;
        line-height: 1.5rem;
        font-size: 0.24rem;
      }

      .box-list {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        // justify-content: center;
        overflow-x: auto;
        scroll-behavior: smooth;

        // transform: translateX(-500px);
        .box-item {
          width: 0.52rem;
          height: 0.5rem;
          margin-right: 0.04rem;
          flex-shrink: 0;
          position: relative;
          background: url(../../assets/images/battle/battleRoom/box-good-bg.png)
            no-repeat;
          background-size: 100% 100%;

          .pic {
            // width: 0.7rem;
            height: 0.3rem;
            margin: 0rem auto 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: relative;

            .img1 {
              position: absolute;
              // height: 0.5rem;
            }

            .img2 {
              // height: 0.3rem;
              position: absolute;
            }
          }

          .name {
            width: 0.42rem;
            height: 0.14rem;
            text-align: center;
            line-height: 0.14rem;
            margin: 0 auto;
            // background: url(../../assets/images/battle/battleRoom/box-title.png)
            //   no-repeat;
            // background-size: 100% 100%;
            // font-size: 0.19rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            font-size: 0.1rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .price {
            // font-size: 0.19rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ff9900;
            text-align: center;
            margin-top: 0.04rem;
            font-size: 0.08rem;
          }

          &::after {
            display: block;
            content: "";
            width: 0.4rem;
            height: 1px;
            // background: #ffd300;
            position: absolute;
            left: 0.9rem;
            top: 1.15rem;
          }

          &:last-child::after {
            display: none;
          }

          &.active {
            background: url(../../assets/images/battle/battleRoom/box-good-bg2.png)
              no-repeat;
            background-size: 100% 100%;

            .pic {
              filter: none;
              transform: scale(1.6);
            }

            p {
              transform: scale(1.6) rotate(45deg);
            }

            .name {
              background: url(../../assets/images/battle/battleRoom/box-title2.png)
                no-repeat;
              background-size: 100% 100%;
            }

            .price {
              // color: #303488;
              font-size: 0.1rem;
              transform: scale(1);
            }
          }
        }

        // .box-item:nth-of-type(1) {
        //   transform: translateX(-200px);
        // }
      }
    }

    .Navigation-R {
      width: 3rem;
      height: 100%;
      display: flex;
      align-items: center;
      // font-size: 0.22rem;
      color: #fff;

      p {
        display: flex;
        margin-left: 0.1rem;
        align-items: center;

        img {
          width: 0.25rem;
          height: 0.25rem;
          vertical-align: middle;
          margin-right: 0.05rem;
        }

        span {
          color: #75cd66;
        }
      }
    }
  }
  .bottom-info {
    // position: absolute;
    // left: 0.3rem;
    // bottom: 0.1rem;
    margin-bottom: 0.2rem;
    width: 100%;
    // height: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    // padding: 0rem;
    div {
      width: 33.33%;
    }
    .p {
      font-size: 0.08rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #6666cc;
      // width: 37%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .round {
      // flex: 1;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #6666cc;
      font-size: 0.12rem;
      text-align: center;
    }
  }

  .take-part-box {
    display: flex;
    width: 100%;
    height: 0.49rem;
    border: 0.01px solid rgba(94, 132, 243, 0.63);
    border-radius: 0.11rem;
    background: #08285f;
    margin-top: 0.16rem;

    .take-l {
      // font-size: 0.19rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #cccccc;
      width: 1.59rem;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 0.18rem;
        height: 0.18rem;
        margin-right: 0.1rem;
      }
    }

    .take-c {
      width: 12rem;

      .robot-list {
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        white-space: nowrap;
        height: 100%;
        align-items: center;

        .item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.54rem;
          height: 0.44rem;
          // font-size: 0.18rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #cccccc;
          background: #1c54b4;
          box-sizing: border-box;
          border: 0.01rem solid #0786c1;
          margin-right: 0.05rem;
          border-radius: 0.02rem;

          .pic {
            width: 0.35rem;
            height: 0.35rem;
            border: 0.01rem solid #0e92da;
            background: #99ccff;
            border-radius: 50%;
            margin-right: 0.07rem;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        &::-webkit-scrollbar {
          height: 0.08rem;
        }

        &::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          border-radius: 0;
          background: #1c54b4;
        }

        &::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          border-radius: 0.03rem;
          background: #666699;
        }
      }
    }

    .take-r {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0.2rem 0.1rem 0.2rem 0;
        border-color: transparent #00ccff transparent transparent;
      }
    }
  }

  .VS-core {
    width: 100%;
    // min-height: 4.18rem;
    // display: flex;
    position: relative;
    overflow: hidden;
    // background: url(../../assets/images/battle/battleRoom/bottom-bg.png)
    //   no-repeat;
    // background-size: 100% 100%;
    border: 0.01rem solid #5d5d9b;
    padding: 0.08rem;
    box-sizing: border-box;
    .all-room-wrap {
      display: flex;
    }
    .all-room {
      // width: 50%;
      min-height: 2.01rem;
    }

    .audiobox {
      position: absolute;
      top: 0.3rem;
      right: 0.28rem;
    }

    .room-code {
      // position: absolute;
      // top: 0.14rem;
      // left: 0;
      width: 100%;
      // height: 0.66rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // font-size: 0.17rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #6666cc;
      margin-bottom: 0.08rem;
      // padding: 0 0.5rem;
      .audio-box {
      }
    }

    .Xian {
      width: 92%;
      height: 0.03rem;
      background: rgb(255, 211, 0);
      position: absolute;
      top: 2.27rem;
      left: 0.5rem;
      z-index: 100;
    }

    // &::after {
    //   display: block;
    //   content: "";
    //   position: absolute;
    //   width: 0.2rem;
    //   height: 2.9rem;
    //   left: 0.5rem;
    //   top: 0.86rem;
    //   background: url(../../assets/images/PublicImg/REDJTF.png) no-repeat center;
    //   background-size: cover;
    // }

    // &::before {
    //   display: block;
    //   content: "";
    //   position: absolute;
    //   width: 0.2rem;
    //   height: 2.9rem;
    //   right: 0.51rem;
    //   top: 0.86rem;
    //   background: url(../../assets/images/PublicImg/REDJTR.png) no-repeat center;
    //   background-size: cover;
    //   z-index: 10;
    // }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 15;
  }

  .block {
    width: 8rem;
    height: 6.7rem;
    background-color: #1f2341;
    border-radius: 0.06rem;
    padding: 0.2rem;
    box-sizing: border-box;

    .block-T {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        // font-size: 0.24rem;
        color: #fff;
      }

      span {
        font-size: 0.28rem;
        color: rgba($color: #ffffff, $alpha: 0.6);
        cursor: pointer;
      }
    }

    .Box-List {
      width: 100%;
      height: 5rem;
      margin: 0.2rem 0 0.4rem;
      overflow: auto;
      display: flex;
      flex-wrap: wrap;

      .Box-List-item {
        width: 1.8rem;
        height: 2.3rem;
        position: relative;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 0.04rem;
        margin-right: 0.1rem;
        margin-bottom: 0.1rem;
        cursor: pointer;
        overflow: hidden;

        &:nth-child(4n) {
          margin-right: 0;
        }

        &:hover {
          .ceng {
            opacity: 1;
          }
        }

        .pic {
          width: 1.8rem;
          height: 1.5rem;
          margin: 0.1rem auto 0;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          .zhao {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }

          img {
            max-width: 100%;
            max-height: 100%;
            position: relative;
            z-index: 10;
          }
        }

        .name {
          width: 100%;
          text-align: center;
          color: #9e9e9e;
          // font-size: 0.14rem;
        }

        .money {
          width: 100%;
          margin-top: 0.1rem;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 0.2rem;
            height: 0.2rem;
            vertical-align: middle;
          }

          span {
            // font-size: 0.14rem;
            color: #75dc9e;
          }
        }
      }
    }

    .Box-List::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 4px;
      /*高宽分别对应横竖滚动条的尺寸*/
      height: 4px;
      scrollbar-arrow-color: red;
    }

    .Box-List::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.6);
      scrollbar-arrow-color: red;
    }

    .Box-List::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }

    .boxbtn {
      width: 100%;
      height: 0.35rem;
      display: flex;
      justify-content: flex-end;

      p {
        width: 0.7rem;
        height: 0.35rem;
        background: #4854c9;
        // font-size: 0.14rem;
        color: #fff;
        text-align: center;
        line-height: 0.35rem;
        cursor: pointer;
      }
    }
  }
}

.popup-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .popup-box {
    width: 100%;
    height: 2.02rem;
    position: relative;
    background: #000;
    font-size: 0.12rem;

    .popup-box-main1 {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: url(../../assets/images/battle/bgdetail.png) no-repeat;
      background-size: 100% 100%;
      padding: 0.46rem 0;
      box-sizing: border-box;

      .popup-box-title {
        position: absolute;
        width: 0.7rem;
        height: 0.2rem;
        left: 0.23rem;
        top: 0.22rem;
        background: url(../../assets/images/battle/bg-title.png) no-repeat;
        background-size: 100% 100%;
      }

      .popup-box-odd {
        padding: 0 1rem;
        display: flex;
        justify-content: flex-end;

        .item {
          display: flex;
          align-items: center;
          margin: 0.1rem;

          @for $i from 1 through 5 {
            .open-lv#{ $i } {
              background: lv-color($i);
            }
          }

          .item-point {
            width: 0.3rem;
            height: 0.3rem;
            margin-right: 0.1rem;
          }

          .item-odd {
          }
        }
      }

      .popup-box-close {
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-top: 0.4rem;
        position: absolute;
        bottom: 0.1rem;
        width: 100%;

        .close-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 0.7rem;
          height: 0.22rem;
          background: url(../../assets/images/battle/btn-bg3.png) no-repeat;
          background-size: 100% 100%;
          // font-size: 0.28rem;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
