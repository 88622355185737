<template>
  <!-- <div class="a-single-box" :class="`bg-img${item?.lv || item?.level}`"> -->
  <div class="a-single-box">
    <div class="gun-img">
      <img style="height: 0.6rem" :src="item?.cover" alt="" srcset="" />
    </div>
    <!-- <div class="name-box">
            <div class="name">是快点睡觉啊了空间</div>
        </div>
        <div class="price-box">
            <div class="price">
                <span>￥{{ item.price }}</span>
            </div>
        </div> -->
  </div>
</template>

<script>
// 使用：盲盒开箱过程
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style lang="scss">
.a-single-box {
  width: 100%;
  height: 100%;
  // background: url('../../assets/images/other/singleboxbg1.png') no-repeat;
  // background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .gun-img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 1rem;
  }
}

.bg-img1 {
  background: url("../../assets/images/other/singleboxbg1.png") no-repeat;
  background-size: 100% 100%;
}

.bg-img2 {
  background: url("../../assets/images/other/singleboxbg2.png") no-repeat;
  background-size: 100% 100%;
}

.bg-img3 {
  background: url("../../assets/images/other/singleboxbg3.png") no-repeat;
  background-size: 100% 100%;
}

.bg-img4 {
  background: url("../../assets/images/other/singleboxbg4.png") no-repeat;
  background-size: 100% 100%;
}
</style>