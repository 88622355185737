<template>
  <div class="comp_goods_item" :class="`lv${handleItem.level}`">
    <div class="Goods_pic1">
      <img :src="handleItem.cover || handleItem.skins.cover" alt="" />
    </div>
    <div class="box-price">
      <div class="odds_percent">
        <div class="d-flex align-items-center" style="padding-left: 0.05rem;">
          <Cprice :size="0.08" :price="((handleItem?.bean || handleItem.skins.bean)*1)?.toFixed(2)" />
        </div>
      </div>
      <div v-if="handleItem.odds_percent" class="dura_alias">
        概率:{{ handleItem.odds_percent }}
      </div>
    </div>
    <div class="p">{{ handleItem.name || handleItem.skins.name }}</div>
  </div>
</template>

<script>
// 用在对战完成之后，下面战利品展示
export default {
  props: {
    item: {},
  },
  computed: {
    handleItem() {
      const item = {
        ...this.item,
        ...this.item.skins,
      };
      return item;
    },
  },
};
</script>

<style lang="scss">
.comp_goods_item {
  width: 100%;
  text-align: center;
  color: #fff;
  // margin: 0.1rem;
  // padding-bottom: 10px;
  padding: 0.08rem 0.05rem 0.05rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  flex-shrink: 0;

  .box-price {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.08rem;
  }

  .odds_percent {
    font-size: 0.19rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    text-align: left;
    // padding-left: 10px;
    box-sizing: border-box;
    color: #dea61f;
  }

  .dura_alias {
    text-align: left;
    padding-right: 10px;
    box-sizing: border-box;
    color: #fff;
    font-size: 0.08rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }

  .Goods_pic1 {
    width: 100%;
    height: 0.4rem;
    margin: 0rem auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;

    img {
      height: 0.4rem;
      // width: 80%;
      // max-width: 100%;
      vertical-align: middle;
      // margin-bottom: 0.07rem;
    }
  }

  .p {
    width: 90%;
    // padding: 0 0.15rem;
    padding-left: 0.03rem;
    text-align: center;
    box-sizing: border-box;
    font-size: 0.08rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 0.02rem;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #75cd66;
    font-size: 16px;
    margin: 5px 0 0;

    img {
      width: 20px;
    }
  }
}
</style>